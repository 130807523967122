<template>
  <div>
    <transition name="fade">
      <div v-if="loading" class="h-full w-full absolute inset-0 flex items-center justify-center z-20 bg-white bg-opacity-50 dark:bg-gray-800/80 backdrop-blur rounded-lg">
        <div class="clock-loader mx-auto" />
      </div>
    </transition>

    <o-alert v-if="errors" :title="$t('auth.loginError')" variant="danger" show class="mt-10 text-sm">
      <span v-for="(error, index) in errors" :key="index">{{ error.join(', ') }}</span>
    </o-alert>

    <div>
      <nuxt-child :accounts.sync="accounts" :loading.sync="loading" />
    </div>
  </div>
</template>

<script>
import {
  OAlert,
} from '@/components/elements'

export default {
  components: {
    OAlert,
  },
  layout: 'auth',
  middleware ({ $auth, redirect }) {
    if ($auth.location) {
      redirect('/auth/login/email')
    }
  },
  data () {
    return {
      loading: false,
      errors: null,
      accounts: null,
    }
  },
}
</script>

<style lang="scss">

.clock-loader {
  --clock-color: white;
  --clock-width: 6rem;
  --clock-radius: calc(var(--clock-width) / 2);
  --clock-minute-length: calc(var(--clock-width) * 0.4);
  --clock-hour-length: calc(var(--clock-width) * 0.2);
  --clock-thickness: 0.4rem;

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--clock-width);
  height: var(--clock-width);
  border: 6px solid var(--clock-color);
  border-radius: 50%;
  filter: drop-shadow(0 0 0.25rem rgba(0,0,0,0.1));

  &::before,
  &::after {
    position: absolute;
    content: "";
    top: calc(var(--clock-radius) * 0.25);
    width: var(--clock-thickness);
    background: var(--clock-color);
    border-radius: 10px;
    transform-origin: center calc(100% - calc(var(--clock-thickness) / 2));
    animation: spin infinite linear;
  }

  &::before {
    height: var(--clock-minute-length);
    animation-duration: 2s;
  }

  &::after {
    top: calc(var(--clock-radius) * 0.25 + var(--clock-hour-length));
    height: var(--clock-hour-length);
    animation-duration: 15s;
  }
}

@keyframes spin {
  to {
    transform: rotate(1turn);
  }
}
</style>
